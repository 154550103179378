import Userform from './userform';
import Navbar from '../Nav/landingnav2';
import Footer from '../Footer';
export default function UserRegisterForm() {
    return (
      <>
 <Navbar transparent/>


  <Userform/>
  <Footer/>
         
       
      



    
    
  


</>
    );
}